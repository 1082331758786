:root {

    --MET-color1:  rgb(0,144,168);
    --MET-color2:  rgb(116,196,215);
    --MET-color3:  rgb(186,222,228);
    --MET-color1b: rgb(73,108,128);
    --MET-color2b: rgba(50,50,50,0.8);
    --MET-color3b: rgb(233,233,233);

    --xl-unit: 32px;
    --large-unit: 16px;
    --base-unit: 8px;
    --medium-unit: 4px;
    --small-unit: 2px;
    --xsmall-unit: 1px;

    /* --grid-gap: var(--xsmall-unit); */
    --grid-gap: 0px;

    /* --box-shadow: 0px; */
    --box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1), 0 4px 8px 4px  rgba(0, 0, 0, 0.12);
}

/*
html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: border-box;
}
*/

body {
    padding: var(--xsmall-unit);
    margin: var(--small-unit);
    font-family: Cabin, Arial, sans-serif;
    background-color: var(--MET-color3b);
    height: calc(100vh - 2*var(--xsmall-unit) -2*var(--small-unit));
}

.title {
    text-align: center;
    line-height: 1.3em;
    font-size: 1em;
    /*font-variant: small-caps;*/
    background-color: var(--MET-color1);
    color: var(--MET-color3b);
    border-radius: var(--small-unit);
    max-width: 100%;
    min-width: 100px;
    /* box-shadow: var(--box-shadow); */
}

.table, .th, .td {
  border: 1px solid black;
  border-collapse: collapse;
}

.header {
    background-color: var(--MET-color1b);
    color: var(--MET-color3b);
    text-transform: uppercase;
    font-size: 1.6em;
    line-height: 2em;
    text-align: center;
    border-radius: var(--small-unit);
    padding-right: 0.5em;
    max-width: 100%;
    min-width: 200px;
    /* box-shadow: var(--box-shadow); */
}

.map {
    margin-bottom: var(--small-unit);
    max-height: 100%;
    max-width: 100%;
    min-width: 100px;
    /* box-shadow: var(--box-shadow); */
}

.map:last-child { margin-bottom: unset; }

.footer {
    font-size: 1em;
    line-height: 1.4em;
    text-align: center;
    color: var(--MET-color1b);
    max-width: 100%;
    min-width: 200px;
}

.bodytext {
    font-size: 1em;
    line-height: 1.4em;
    text-align: left;
    color: var(--MET-color1b);
    padding-left: 1.0em;
    max-width: 100%;
    min-width: 200px;
}


.grid {
    display: grid;
    height: calc(98vh - 2*var(--xsmall-unit) - 2*var(--small-unit));
    grid-template-columns: 1fr;
    grid-template-rows: 3em repeat(3, 1.4em calc(((67vh - (3em + 2em + 2*6px + 4*var(--small-unit)))/3) - (1.4em + var(--small-unit)))) 5em 25vh;
    grid-template-areas:
    'header' 'title0' 'map0' 'title1' 'map1' 'title2' 'map2' 'footer' 'bodytext';
    grid-gap: var(--small-unit);
}


/* height: calc(98vh - 2*var(--xsmall-unit) - 2*var(--small-unit));
 grid-template-rows: 3em repeat(3, 1.4em calc(((97vh - (3em + 2em + 2*6px + 4*var(--small-unit)))/3) - (1.4em + var(--small-unit)))) 2em; */

@media only screen and (min-device-width: 375px) and (orientation: landscape) {

    .grid {
	height: calc(80vh - 2*var(--xsmall-unit) - 2*var(--small-unit) + 43em);
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 3em 1.6em calc(80vh - (6.6em + 4*var(--xsmall-unit) + 7*var(--small-unit))) 2em 43em;
	grid-template-areas:
	    'header header header'
	    'title0 title1 title2'
	    'map0 map1 map2'
            'footer footer footer'
            'bodytext bodytext bodytext';
	grid-column-gap: var(--small-unit);
	grid-row-gap: var(--xsmall-unit);
	padding: 0;
	margin: 0;
    }

    .map { margin-bottom: unset; }

    .header { font-size: 1.6em; line-height: 1.8em; }

    .title { font-size: 1em; line-height: 1.6em; }

}

.fare0_darkgreen  { background-color: #00b5af; }
.fare1_yellow     { background-color: #ffee00; }
.fare2_orange     { background-color: #ffa500; }
.fare3_lightred   { background-color: #ff0000; }
.fare3_darkred    { background-color: #aa0000; }


ul.legend {
    list-style: none; margin: 0.3em; padding: 0.1em; display: inline-block;
}
ul.legend li {
    float: left; margin-right: var(--base-unit); margin-left: var(--small-unit);
    font-variant: small-caps;
}
ul.legend div {
    float: left; width: 3em; height: 1.2em; margin-right: var(--medium-unit);
    box-shadow: var(--box-shadow);
}


.header { grid-area: header; }

.footer { grid-area: footer; }

.title0 { grid-area: title0; }
.title1 { grid-area: title1; }
.title2 { grid-area: title2; }

.map0 { grid-area: map0; }
.map1 { grid-area: map1; }
.map2 { grid-area: map2; }

.bodytext { grid-area: bodytext; }

.logo {
    float: left;
    max-block-size: 100%;
}
.logo_dsb {
    float: right;
    max-block-size: 50%;
    /* vertical-align: middle;*/
    margin-top: 11px;
}
